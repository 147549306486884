// src/pages/MyPage.js
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useProducts} from '../hooks/useProducts';
import ProductList from '../components/Products/ProductList';
import Modal from '../components/Modals/Modal';
import authService from '../services/authService';
import {useNavigate,useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserEdit} from "@fortawesome/free-solid-svg-icons";
import {faArrowRotateBackward, faPlus} from "@fortawesome/free-solid-svg-icons";
const VariantsPage = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [selectedproductid, setSelectedproductid] = useState(null);
    const {productid} = useParams(); // Načtení ID z URL
    const navigate = useNavigate();
    const token = authService.getToken();
    const {products, loading, error, handleDelete} = useProducts(token,'variants',productid );

    useEffect(() => {
        if (!authService.isAuthenticated()) {
            navigate('/login');
        }
    }, [navigate]);

    const handleDeleteClick = (productid) => {
        setSelectedproductid(productid);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        handleDelete(selectedproductid);
        setShowModal(false);
    };

    const handleLogout = () => {
        authService.logout();
        navigate('/login');
    };

    return (
        <div className="box-padding">
            <div className="my-header">
                <h1 className="mar-0">{t('myProductsTitle')}</h1>
                <div className="box-row">
                    <button className="button-60" onClick={() => navigate('/add-product')}>
                        <FontAwesomeIcon icon={faPlus}/> {t('addProduct')}
                    </button>
                    <button className="button-60" onClick={() => navigate(`/add-variants/${productid}`)}>
                        <FontAwesomeIcon icon={faPlus}/> {t('addVariants')}
                    </button>
                    <button className="add-chat-button" onClick={() => navigate('/my-profile')}>
                        <FontAwesomeIcon icon={faArrowRotateBackward}/> {t('back')}
                    </button>
                </div>
            </div>

            {loading ? (
                <p>{t('loading')}</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <ProductList
                    products={products.data}
                    onEdit={(id) => navigate(`/edit-product/${id}`)}
                    onView={(id) => navigate(`/product/${id}`)}
                    onStatistics={(id) => navigate(`/statistics/${id}`)}
                    onDelete={handleDeleteClick}
                />
            )}

            {showModal && (
                <Modal
                    title={t('confirmDelete')}
                    onClose={() => setShowModal(false)}
                    onConfirm={handleConfirmDelete}
                    deleteTitle={t('delete')}
                />
            )}
        </div>
    );
};

export default VariantsPage;