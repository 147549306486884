import React, { useContext, useState, useEffect } from 'react';
import { SearchContext } from '../Context';
import heart from "../assets/icons/heart.png";
import '../assets/css/search.css';
import {searchProducts} from "../services/productsService";

const SearchPage = () => {
    const { searchParams } = useContext(SearchContext);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Fetch data from the server whenever searchParams change
        const fetchData = async () => {
            try {
                const response = await searchProducts(searchParams)

                const data = await response.json();
                setProducts(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [searchParams]);

    return (
        <div className="search-page box-padding-add">
            <div className="search-product-grid">
                {products.length > 0 ? products.map((product) => (
                    <div className="search-grid-item" key={product.id}>
                        <a href={`/detail/${product.id}`} className="search-product-card">
                            <img src={product.mainFoto} alt={product.name} className="search-product-image" />
                            <div className="search-labels-box">
                                {product.approved === 0 && <div className="search-label">Vyprodané</div>}
                                {product.discount && <div className="search-label">Sleva</div>}
                            </div>
                            <img src={heart} className="search-heart-icon" alt="heart icon" />
                            <div className="search-product-info">
                                <h3 className="search-product-title">{product.name}</h3>
                                <p className="search-product-description">{product.description}</p>
                                <p className="search-product-price">Cena za noc: {product.price} Kč</p>
                            </div>
                        </a>
                    </div>
                )) : <p>No products found</p>}
            </div>

            <div className="search-pagination">
                {/* Pagination buttons */}
                <button className="search-pagination-button">«</button>
                <button className="search-pagination-button">1</button>
                <button className="search-pagination-button">2</button>
                <button className="search-pagination-button">3</button>
                <button className="search-pagination-button">»</button>
            </div>
        </div>
    );
};

export default SearchPage;
