import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDropzone} from 'react-dropzone';
import {useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {useParams} from "react-router-dom";


const UpdateMapCenter = ({position}) => {
    const map = useMap();
    useEffect(() => {
        if (position) {
            map.setView(position, 15); // Změna pozice a zoomu mapy
        }
    }, [position, map]);
    return null;
};

const BasicInfoVariant = ({formState, handleChange}) => {
    const {t} = useTranslation();
    const [mainFotoPreview, setMainFotoPreview] = useState(null);
    const {id} = useParams(); // Načtení ID z URL
    const isEdit = !!id; // Určení, zda se jedná o editaci
    useEffect(() => {
        // Nastavte `mainFotoPreview` na URL obrázku při změně `formState`
        if (formState.mainFoto) {
            setMainFotoPreview(isEdit ? 'https://chatynapronajem.cz/backend/www/Soubory/Products/' + id + '/Variants/' + formState.mainFoto : formState.mainFoto);
        }
    }, [formState.mainFoto]);


    // Handle file drop and generate preview URL
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setMainFotoPreview(previewUrl);

            const event = {
                target: {
                    id: 'mainFoto',
                    value: file,
                },
            };
            handleChange(event);
        }
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false,
    });

    return (
        <div>
            <div className={"main-form"}>
                <div className="form-group start">
                    <div
                        {...getRootProps({className: `dropzone min-w-300 ${isDragActive ? 'active' : ''}`})}
                        style={{
                            backgroundImage: mainFotoPreview ? `url(${mainFotoPreview})` : 'none',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <input {...getInputProps()} />
                        {!mainFotoPreview && (
                            <p>{isDragActive ? t('dropHere') : t('dragOrClick')}</p>
                        )}
                    </div>
                </div>

                <div className={'flex flex-column start w-100 max-w-500'}>
                    <div className="form-group max-w-500 text-start">
                        <label htmlFor="name">{t('productName')}</label>
                        <input
                            type="text"
                            id="name"
                            placeholder={t('productNamePlaceholder')}
                            value={formState.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group max-w-500 start gap-20">
                        <div className={'flex flex-row gap-20 beetween'}>
                            <div className="form-group text-start">
                                <label htmlFor="priceFrom">{t('priceFrom')}</label>
                                <input
                                    type="number"
                                    id="priceFrom" value={formState.priceFrom}
                                    placeholder={t('priceFrom')}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group text-start">
                                <label htmlFor="priceTo">{t('priceTo')}</label>
                                <input
                                    type="number"
                                    id="priceTo" value={formState.priceTo}
                                    placeholder={t('priceTo')}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className={'flex flex-row gap-20 beetween'}>
                            <div className="form-group text-start">
                                <label htmlFor="bedrooms">{t('bedrooms')}</label>
                                <input
                                    type="number"
                                    id="bedrooms"
                                    className={'text-center'}
                                    min="0"
                                    value={formState.bedrooms}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group text-start">
                                <label htmlFor="bathrooms">{t('bathrooms')}</label>
                                <input
                                    type="number"
                                    id="bathrooms"
                                    className={'text-center'}
                                    min="0"
                                    value={formState.bathrooms}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className={'flex flex-row gap-20 beetween'}>
                            <div className="form-group text-start">
                                <label htmlFor="toilets">{t('toilets')}</label>
                                <input
                                    type="number"
                                    className={'text-center'}
                                    id="toilets"
                                    min="0"
                                    value={formState.toilets}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="form-group text-start">
                                <label htmlFor="parkingSpaces">{t('parkingSpaces')}</label>
                                <input
                                    type="number"
                                    className={'text-center'}
                                    id="parkingSpaces"
                                    min="0"
                                    value={formState.parkingSpaces}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'flex flex-column start w-100'}>
                    <div className="form-group text-start w-available">

                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicInfoVariant;
