import React from "react";
import {Route, Routes} from 'react-router-dom'; // Přidej tento řádek
import './assets/css/App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from "./pages/RegistrationPage";
import SearchPage from "./pages/SearchPage";
import MyPage from "./pages/MyPage";
import {SearchProvider} from './Context';
import ForgotPassPage from "./pages/ForgotPassPage"; // Ověřte, že cesta je správná
import './Language';
import AddProduct from "./pages/AddProduct";
import VariantsPage from "./pages/VariantsPage";
import ProductDetail from "./pages/ProductDetail";
import Favourite from "./pages/Favourite";
import SimplePage from "./pages/SimplePage";
import StatisticsPage from "./pages/StatisticsPage";
import EditProfilPage from "./pages/EditProfilPage";
import BlogPage from "./pages/BlogPage";
import ArticlePage from "./pages/ArticlePage";
import AddVariants from "./pages/AddVariants";
function App() {
    const items = ["Chaty a chalupy", "Roubenky", "Penziony", "Apartmány", "Ubytování v soukromí", "Hotely", "Ubytovny", "Zajmavé místa"];
    return (
        <div className="App">
            <div className="container">
                <Navbar items={items}/>
                <SearchProvider>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/neocrel" element={<HomePage/>}/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/register" element={<RegisterPage/>}/>
                        <Route path="/blog" element={<BlogPage/>}/>
                        <Route path="/search/:locality" element={<SearchPage/>}/>
                        <Route path="/forgot-password" element={<ForgotPassPage/>}/>
                        <Route path="/my-profile" element={<MyPage/>}/>
                        <Route path="/add-product" element={<AddProduct/>}/>
                        <Route path="/edit-product/:id" element={<AddProduct/>}/>
                        <Route path="/accommodation-options/:productid/" element={<VariantsPage/>}/>
                        <Route path="/detail" element={<ProductDetail/>}/>
                        <Route path="/favourite" element={<Favourite/>}/>
                        <Route path="/edit-profile" element={<EditProfilPage/>}/>
                        <Route path="/simple/:page" element={<SimplePage />} />
                        <Route path="/statistics/:id" element={<StatisticsPage />} />
                        <Route path="/add-variants/:id" element={<AddVariants />} />
                        <Route path="/article/:slug" element={<ArticlePage />} />
                    </Routes>
                </SearchProvider>
            </div>
            <Footer/>
        </div>
    );
}

export default App;